import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import ServiceFooter from '../../components/ServiceFooter'

const Bike = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="temp" lang="ja" />
        <title>{`バイク用ロック板ユニット | ${title}`}</title>
        <meta
          name="description"
          content="ITC-BLは、車両ロックシステムの経験と実績を活かし開発した、バイク用ロック装置。ニーズに応える使いやすさと、省スペースにこだわったシステムです。"
        />
      </Helmet>

      <div className="common-header clearfix">
        <p className="category pc-only">製品・サービス</p>
        <h1>バイク用ロック板ユニット</h1>
        <p className="category sp-only">製品・サービス</p>

        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /
          <li>
            <Link to={`/service`}>製品・サービス</Link>
          </li>
          /<li>バイク用ロック板ユニット</li>
        </div>
      </div>

      <div className="main-wrapper bike">
        <div className="row">
          <div className="container empty"></div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h2>バイク用ロック板ユニット</h2>
          </div>
        </div>

        <div className="row">
          <div className="container top">
            <h4>
              ITC-BLは、車両ロックシステムの経験と実績を活かし開発した、バイク用ロック装置。ニーズに応える使いやすさと、省スペースにこだわったシステムです。
            </h4>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>バイク用ロック板ユニットITC-BL 標準仕様</h3>
          </div>
        </div>

        <div className="row">
          <div className="container cont-01">
            <picture>
              <source
                type="image/webp"
                srcset="/assets/images/common/service-bike-01.webp"
              />
              <img
                className="bike-01"
                src="/assets/images/common/service-bike-01.jpg"
                alt=""
              />
            </picture>

            <h5>標準仕様</h5>

            <table>
              <tbody>
                <tr>
                  <th>外形寸法</th>
                  <td className="multi">W900mm×D940mm×H250mm</td>
                </tr>
                <tr>
                  <th>重量</th>
                  <td>約53kg</td>
                </tr>
                <tr>
                  <th>電源電圧</th>
                  <td>DC24V ±10%</td>
                </tr>
                <tr>
                  <th>消費電力</th>
                  <td>動作時30W (DC24V)</td>
                </tr>
                <tr>
                  <th>使用環境</th>
                  <td>温度 0℃〜45℃　湿度 結露しないこと</td>
                </tr>
                <tr>
                  <th>ロック方式</th>
                  <td>部位に合わせた無段階ロック方式</td>
                </tr>
                <tr>
                  <th>車両検知方式</th>
                  <td>光電管センサー方式</td>
                </tr>
                <tr>
                  <th>操作部</th>
                  <td>上昇・下降</td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td>ワンタッチロックキー：メンテナンス時の開閉が安易</td>
                </tr>
              </tbody>
            </table>

            <div className="notes">
              ※ ゲート式バイクパーキングシステムもご用意しています。
            </div>
          </div>
        </div>

        <ServiceFooter />
      </div>
    </Layout>
  )
}

export default Bike
